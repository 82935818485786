var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.reports,
      "total": _vm.reportsTotal,
      "loading": _vm.reportsLoading.list,
      "columns": _vm.columns,
      "page-size": 20,
      "fetch-items": _vm.refresh,
      "default-sort-key": "createdAt",
      "default-sort-direction": -1
    },
    scopedSlots: _vm._u([{
      key: "createdAt",
      fn: function (_ref) {
        var data = _ref.data;
        return [_vm._v(" " + _vm._s(_vm._f("date")(data.createdAt, 'YYYY-MM-DD HH:mm:ss')) + " ")];
      }
    }, {
      key: "actions",
      fn: function (_ref2) {
        var data = _ref2.data;
        return [_c('div', {
          staticClass: "d-flex no-wrap"
        }, [data.format === 'chart' ? _c('router-link', {
          staticClass: "btn btn-sm btn-text-primary",
          attrs: {
            "to": {
              name: 'reports-chart',
              params: {
                id: data._id
              }
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-eye mr-1"
        }), _vm._v("View")]) : _vm._e(), _vm.$auth.isAdmin && data.batchId && data.type === 'pdf' ? _c('router-link', {
          staticClass: "btn btn-sm btn-text-warning",
          attrs: {
            "to": {
              name: 'machine-pdf',
              query: {
                reportId: data._id,
                batchId: data.batchId
              }
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-lock mr-1"
        }), _vm._v("Admin Only - View")]) : _c('router-link', {
          staticClass: "btn btn-sm btn-text-primary",
          attrs: {
            "to": {
              name: 'reports-download',
              params: {
                id: data._id
              }
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-download mr-1"
        }), _vm._v("Download")]), _vm.$auth.isAdmin ? _c('router-link', {
          staticClass: "btn btn-sm btn-text-info",
          attrs: {
            "to": {
              name: 'reports-download',
              params: {
                id: data._id
              },
              query: {
                regenerate: 'true'
              }
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh mr-1"
        }), _vm._v("Re-generate")]) : _vm._e()], 1)];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }